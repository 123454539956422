
import '../../Styles/index.css';

import { useCallback,useState, useEffect } from 'react';
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
  } from "tw-elements-react";

import axios from 'axios';
import { Form } from 'react-router-dom';
const token = localStorage.getItem('token');

const izenuApi = axios.create();
izenuApi.defaults.headers.Authorization = 'Bearer ' + token;




const SearchArtists = ()=>{
    const [strSearch, setStrSearch] = useState("");
    const [artists, setArtists] = useState([]);
    const searchArtist = useCallback(async ()=>{

        const {data:r} = await izenuApi.get('https://www.izenu.ao/api/admin/album/searchUser.php?artist=' + strSearch);
        
        if(r.response){
            setArtists(r.artists);
        }
        console.log(r);

    },[strSearch]);
    return (<div class="mb-5">
            
                <h5 className="mb-5 font-bold text-left">Pesquise pelo artista que deseja adicionar o album</h5>
                <div className="flex-1 w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light">
                    <input onChange={(e)=>setStrSearch(e.target.value)}  class="flex-1 border-0" type="text" id="title"  placeholder="Pesquisar artista" required />
                    <button type="button" onClick={()=>searchArtist()} class="text-silver bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><span className='text-gray-100'>Pesquisar</span></button>
                </div>
                <div class="mb-5">
                    <label for="genre" class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">Artista</label>
                    
                    <select id="genre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                    {artists.map((e, i)=>{
                        return (<option>{e.name}</option>)
                    })}
                    </select>
                </div>
            </div>)
}
export default function NewAlbum(){
    
    const [artwork, setArtwork] = useState(null);
    const [title, setTitle] = useState(null);
    const [info, setInfo] = useState(null);
    const [price, setPrice] = useState(null);
    const [launchDate, setLaunchDate] = useState(null);
    const [artistId, setArtistId] = useState(undefined);

    
    const [strSearch, setStrSearch] = useState("");

    const onFileChange = useCallback((file)=>{
        
        
        const types = ['png','jpg', 'jpeg'];

        //alert(types.indexOf(file.name.split('.')[1].toLowerCase()));
        
        if(types.indexOf(file.name.split('.').slice(-1)[0].toLowerCase()) == -1){
            alert('Tipo de ficheiro invalido ');
            console.log(file);
            return;
        }

        const reader = new FileReader();
        
        reader.onload = ()=>{
            //setImage({file:file, result:reader.result});
        }

        reader.readAsDataURL(file);
    });
    
    const saveAlbum = useCallback(()=>{
        const data = {artistId, info, title, artwork, price, launchDate};
        
    },[artistId, info, title, artwork, price, launchDate]);

   

    return (<>
        
                <header className="p-5">
                    <h1 className="text-3xl font-bold text-left">Adicionar album</h1>
                </header>
                <div className='px-40 justify-center'>
                    
                <form class="max-w-lg mx-auto">

                    <SearchArtists />
                    
                    <div class="mb-5">

                        <label class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="user_avatar">Capa do album</label>
                        <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file"/>
                        <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">A capa do album é importante para identificaçao na plataforma</div>
                    </div>
                    
                    <div class="mb-5">
                        <label for="title" class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titulo do album</label>
                        <input type="text" id="title" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Titulo do album" required />
                    </div>
                   
                    <div class="mb-5">
                        <label for="price" class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">Preço Kz</label>
                        <input type="number" id="price" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required />
                    </div>
                    
                    <div class="mb-5">
                        <label for="info" class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">Informação adicional</label>
                        <textarea onChange={(e)=>setInfo(e.target.value)} id="info" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..."></textarea>
                    </div>
                    <div class="mb-5">
                        <label for="launch-date" class="font-bold block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data de lançamento {launchDate}</label>
                        <input onChange={(e)=>setLaunchDate(e.target.value)} type="datetime-local" id="launch-date" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required />
                    </div>
                    <div class="mb-5">
                        <button type="submit" class="text-silver bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><span className='text-gray-100'>Adicionar este album</span></button>

                    </div>
                    
                </form>
                                                
                </div>
                
            </>)
}