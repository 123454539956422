import { useEffect, useCallback, useState } from 'react';
import { Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    ArcElement,
    Legend
} from 'chart.js';

import izenuAPI from '../../services/izenuAPI';
import { Doughnut, Line } from "react-chartjs-2";

import { faker } from '@faker-js/faker';
import axios from 'axios';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
export const options = {
  responsive: true,
  
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },    
  },
};
  
const labels = ['Segunda', 'Terça','Quarta', 'Quinta', 'Sexta', 'Sabado', 'Domingo'];
  
const data = {
    type:'line',
    labels: labels,
    datasets: [{
      label: 'My First Dataset',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: true,
      backgroundColor: '#3992ff',
      borderColor: '#3992ff',
      tension: 0.4
    }]
  };

export default function Dashboard(){
    const [dashData, setDashData] = useState([]);

    const fetchDashboardData = useCallback(async ()=>{
      try{
        const end_point = 'https://www.izenu.ao/api/distributor/fetchDashboardData.php?distributor_id=' +1;
        const {data:response} = await izenuAPI.get(end_point);
        setDashData(response);

      }catch(error){
        console.log(error);
      }
      
    });

    useEffect(()=>{
      fetchDashboardData();
    },[]);

    return(
        <>
            <div style={{display:'flex', flexDirection:'row'}}>   
                    <h1 style={{textAlign:'left', flex:1, marginBottom:20}}>Dashboard</h1>
                    <button style={{marginRight:10}}>
                      <h1>
                          <span style={{color:'#3992ff'}}>{dashData.total} </span>
                           streams
                      </h1>
                    </button>
                    <button>
                      <h1><span style={{color:'#3992ff'}}>{dashData.earnings} </span> Kz</h1>
                    </button>
                </div>
           
            <div className="content-container">
                <div style={{ display:'flex', marginBottom:40, flexDirection:'row', height:300}}>
                    <div className='box-shadow' style={{flex:1, padding:40, alignItems:'center', flexDirection:'column', justifyContent:'center', display:'flex', backgroundColor:'#ddd', marginLeft:20}}>
                      <h2>Artistas</h2>
                      <span style={{fontSize:40, color:'#3992ff'}}>{dashData.artists}</span>
                    </div>

                    <div className='box-shadow' style={{flex:1, padding:40, alignItems:'center', flexDirection:'column', justifyContent:'center', display:'flex', backgroundColor:'#ddd', marginLeft:20}}>
                      <h2>Músicas</h2>
                      <span style={{fontSize:40, color:'#3992ff'}}>{dashData.songs}</span>
                    </div>
                </div>
                {/*<div>
                    <h2 style={{textAlign:'left', marginBottom:20}}>
                        Streaming gráfico
                    </h2>
                    <div style={{height:300, width:'100%'}}>
                        <Line style={{width:'100%'}} options={options} data={data} />
                    </div>
                  </div>*/}
                   
            </div>
        </>
        
        
    );
}