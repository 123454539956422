import { useState, useEffect, useCallback } from 'react';
import '../../Styles/ArtistRoot.css';

import COUNTRIES from '../../utils/Countries';
import GENRES from '../../utils/Genres';
import {Link} from "react-router-dom";
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {BiSolidPlaylist} from 'react-icons/bi';
import { Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import izenuAPI from '../../services/izenuAPI';
import {BsFileEarmarkImage} from 'react-icons/bs';

const ARTISTS  = [
    {name:'Etiene Adriano', user_id:1},
    {name:'Cristina Adriano', user_id:2},
    {name:'Adriano de Carvalho', user_id:3},
    {name:'Elizetrina Pinto', user_id:4},
    {name:'Adriana Adriano', user_id:5},
    {name:'Rafael Simão', user_id:6},
    {name:'Chayene Simão', user_id:7},
    {name:'Simone Simão', user_id:8},
];

const uuid = () =>{

    const alfaNumeric = '1234567890ABCDEFGHIJKLMNOPQRSTUVXWYZabcdefghijklmnopkrstuvxwyz';
    let str = alfaNumeric.split("");
    let str2 = '';
    for(var i = 0; i < 8; i++){
        str2 += '' + str[Math.ceil(Math.random() * str.length)];
    }
    return str2;
}
export default function Albums(){
    const [checked, setChecked] = useState(false);
    const [tab, setTab] = useState(0);
    const [playlists, setPlaylists] = useState([]);
    const [image, setImage] = useState(null);
    const handleCoverChange =  useCallback((event)=>{
        console.log(typeof(event.currentTarget.files.length));
            if(event.currentTarget.files.length > 0){
                const file = event.currentTarget.files[0];
        
                const types = ['png','jpg', 'jpeg'];

                //alert(types.indexOf(file.name.split('.')[1].toLowerCase()));
                
                if(types.indexOf(file.name.split('.').slice(-1)[0].toLowerCase()) == -1){
                    alert('Tipo de ficheiro invalido');
                    return;
                }

                const reader = new FileReader();
                
                reader.onload = ()=>{
                    setImage({file:file, result:reader.result});
                }

                reader.readAsDataURL(file);
            }
        
    
    },[image]);

    const handleSavePlaylistCover = useCallback(async () => {

        try{
            const id = uuid();
            const d = new Date();
            const date = {day: d.getUTCDate(), year: d.getUTCFullYear(), month: d.getUTCMonth() + 1};
            const cover_name = `izenu_cover_${date.year}_${date.month}_${date.day}_${id}.${image.file.type.split('/')[1]}`;

            const form = new FormData();
            form.append('files', image.file);
            form.append('key', 'users/4/files/playlists/' + cover_name);

            const {data: r} = await axios.post('https://server.izenu.net/playlist/upload-cover', form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
            
            return {response:r, cover_name};

        }catch(error){
            console.log("Uploading error: " + error);
        }
        
      }, [image]);

    const createPlaylist =  useCallback(async(data) =>{
        
        const upload_cover = await handleSavePlaylistCover();
        if(upload_cover.response.message == 'success!'){
            data.user_id = 4;
            data.country = 'Angola';
            data.cover = upload_cover.cover_name;
            
            const {data:r} = await izenuAPI.post('https://www.izenu.ao/api/distributor/playlists/createPlaylist.php', data);

            if(r.message){
                alert('Uploaded successfully')
            }else{
                console.log('Impossivel criar playlist!');
            }

        }else{
            console.log('Cant aupload cover');
        }


    });
    const listPlaylists = useCallback(async()=>{
        const distributor_id = 1;
        const END_POINT = 'https://www.izenu.ao/api/distributor/playlists/listAlbums.php?distributor_id='+distributor_id;
        const {data:r} = await izenuAPI.get(END_POINT);
        if(r.response){
            setPlaylists(r.playlists);
        }
    });

    const renderPlaylists = useCallback(()=>{
        if(playlists.length == 0){
            return(<div style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginBottom:40, flexDirection:'row', flex:1, height:'100%'}}>
                       <h1 style={{flex:1, marginBottom:20}}>Upsi, ainda não adicionou albums!</h1>
            </div>)
            
        }else{
            return (
                <div style={{ display:'flex', flexWrap:'wrap', marginBottom:40, flexDirection:'row', flex:1, height:'100%'}}>
                {playlists.map((e, i)=>{
                return (
                    <Link to={`/album/${e.playlist_id}`}>                             
                        <div style={{display:'flex',cursor:'pointer', marginRight:20, flexDirection:'column'}}>
                            <div>
                                {e.cover != null && e.cover != ""?(<img src={e.cover} style={{width:225, height:225}} className='box-shadow'/>):(
                                    <div style={{width:225, height:225, display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"#ddd"}} className='box-shadow'>
                                        <BiSolidPlaylist size={60} color="#666"/>
                                    </div>
                                )}
                            </div>
                            <div>
                                <h5>{e.title}</h5>
                                <h5 style={{fontWeight:400}}>{e.name}</h5>
                            </div>
                        </div>
                        </Link>
                    )
            })}</div>)
        }
    },[playlists]);
    useEffect(()=>{
        listPlaylists();
    },[]);
    return( <>
                <div style={{display:'flex', flexDirection:'row'}}>   
                    <h1 style={{textAlign:'left', flex:1, marginBottom:20}}>Albums</h1>
                    <button onClick={()=>setTab(1)} className='btn-grey'>
                        <AiOutlinePlusCircle /> Adicionar playlist
                    </button>
                </div>
                <div style={{height: '100%'}} className="content-container">
                    {tab==0?(
                        <>
                        
                       {renderPlaylists()}
                    </>
                    ):(
                        <div style={{width:'100%', height:'auto',padding:40, borderRadius:20,backgroundColor:'#fafafa', display:'flex',alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
                        <h3 style={{width:'100%', textAlign:'left', marginBottom:40, marginTop:40}}>Media</h3>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginRight:9}}>
                                <div className='form-item'>
                                    <h5>Capa</h5>
                                </div>
                                
                            </div>
                            <div>
                                <div className='form-item'>
                                
                                    <input  id="cover" onChange={(e)=>handleCoverChange(e)} name="cover" className='text-input' type="file" placeholder="Repetir passe"/>
                                    <label for="cover">
                                        <div className='coverContainer'>
                                            {image != null?(
                                                <>
                                                    <img src={image.result} style={{width:'100%', objectFit:'cover', borderRadius:8, height:'100%'}}/>
                                                    <div style={{position:'absolute'}}>
                                                        <BsFileEarmarkImage style={{marginTop:2, marginRight:4}} size={14} color="#fff" />
                                                        <span style={{color:"#fff"}}>
                                                            Imagem selecionada<br></br>
                                                            <span style={{color:"#fff"}}>
                                                                Clica para escolher outra
                                                            </span>
                                                        </span>
                                                    </div>
                                                </>
                                                
                                            ):(
                                                <>
                                                    <BsFileEarmarkImage style={{marginTop:2, marginRight:4}} size={14} color="#999" />
                                                    Selecionar imagem
                                                </>
                                            )}
                                            
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <h3 style={{width:'100%', textAlign:'left', marginBottom:40, marginTop:40}}>Dados da playlist</h3>
                        <Formik
                                    initialValues={{
                                        title:'',
                                        info:'',
                                        singer:''
                                        
                                    }}
                                   
                                    validate={values => {
                                        const errors = {};
                                        if (image == null) {
                                            errors.image = 'A capa da música é obrigatoria';
                                        }
                                        return errors;
                                    }}
                               
                                    onSubmit={(values, { setSubmitting, resetForm }) =>{
                                        //console.log('submit');
                                        alert(JSON.stringify(values, null, 2));
                                        createPlaylist(values);
                                        resetForm({values:''});
                                        setSubmitting(true);
                                       //insertMusic(values);
                                    }}
                                    validationSchema={Yup.object({
                                        title:Yup.string().required('Campo obrigatorio').min(6, 'No mínimo 6 caracteres').max(30, 'No maximo caracter 30'),
                                        info:Yup.string().max(255, 'No maximo 255 caracteres'),
                                    })}
                                >
                                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) =>(
                                    <form onSubmit={(e)=>{e.preventDefault(); handleSubmit();}}>
                                        <div style={{display:'flex', flexDirection:'row'}}>
                                            <div style={{marginRight:9}}>
                                                <div className='form-item'>
                                                    <h5>Título</h5>
                                                </div>
                                                <div className='form-item'>
                                                    <h5>Artista</h5>
                                                </div>
                                                <div className='form-item'>
                                                    <h5>Descrição</h5>
                                                </div>
                                            </div>
                                            <div>
                                            
                                                <div className='form-item'>
                                                    {errors.title && touched.title ? (<span className='alert-error'>{errors.title}</span>) : null}                   
                                                    <input onChange={handleChange} onBlur={handleBlur} name="title" className='text-input' type="text" placeholder="Título da playlist"/>
                                                </div>
                                                
                                                <div className='form-item'>
                                                    <select  name="singer" className='select-input'>
                                                        {ARTISTS.map((e,i)=>{
                                                            
                                                            return i==0?(<option  default key={JSON.stringify(i)} value={e.name}>{e.name}</option>):(<option key={JSON.stringify(i)} value={e.name}>{e.name}</option>);
                                                        })}
                                                    </select>
                                                </div>
                                                
                                                <div className='form-item'>
                                                    {errors.info && touched.info ? (<span className='alert-error'>{errors.info}</span>) : null} 
                                                    <textarea  onChange={handleChange} onBlur={handleBlur} name="info" type="text" className='text-area' placeholder="Sobre a playlist"></textarea>
                                                </div> 
                                                
                                            </div>
                                        </div>
                                        <div>
                                        
                                                <button type="submit" className='btn-blue'>
                                                    Salvar playlist
                                                </button>
                                        
                                            
                                            <button onClick={()=>setTab(0)} className='btn-grey-2'>
                                                Cancelar
                                            </button>
                                        </div>
                                    </form>
                            )}
                        </Formik>
                                        
                    </div>
                    )}
                    
                </div>
            </>); 
}