import { useState, useEffect, useCallback, useRef } from 'react';
import ReactPlayer from 'react-player'

import '../../Styles/ArtistRoot.css';
import '../../services/izenuAPI';


import { useParams } from 'react-router-dom';
import {AiOutlinePlusCircle, AiOutlineClose} from 'react-icons/ai';
import Carousel from '../../component/Carousel';
import { Formik} from 'formik';
import * as Yup from 'yup';
import PostViewModal from './components/PostViewModal';
import {RiCheckboxFill, RiCheckboxBlankLine} from 'react-icons/ri';
import axios from 'axios';
import izenuAPI from '../../services/izenuAPI';

const PlaylistItem = ({data:e, musicId, closeModal})=>{
    const [check, setCheck] = useState(false);
    const handleChange = useCallback(async ()=>{
        
        if(check == false){
            const {data:r} =  await axios.post('https://www.izenu.ao/api/playlist_musics/createPlaylistMusics.php', {
                playlistId: e.id,
                musicId:musicId,
            }); 
            console.log(r);
            setCheck(true);
            closeModal(false);
        }else{
            setCheck(false);
            closeModal(false);
        }
        
    });
    return(<button onClick={()=>handleChange()} style={{padding:10, display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                {check?(
                    <RiCheckboxFill size={30} color="#3992ff"/>
                ):(
                    <RiCheckboxBlankLine size={30} color="#172b4d"/>
                )}
                
                {e.title}
            </button>);
};

const Player = ({musicPlayer, playing, setPlaying})=>{
   

    /*useEffect(()=>{
        if(musicPlayer!= null){

        
        if(musicPlayer.title != music.title && musicPlayer!= null){
            setPlaying(true);
        }
    }   
    },[musicPlayer])*/
    
    const handleMusicCover = useCallback((cover)=>{
        if(cover == null || cover == undefined){
            return (
                <div style={{width:45, backgroundColor:"#777", height:45, borderRadius:4}}>

                </div>);
        }else{
            return (
                <img class="w-8 h-8 border" src={cover.photo_low_quality_url}/>
          
            );
        }
    });

    if(musicPlayer != null){
        return ( <div class="border  w-full ">
                    <ul class="w-full bg-gray-900">
                        <li class="pb-3 sm:pb-4">
                            <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                <div class="flex-shrink-0">
                                    {handleMusicCover(musicPlayer.cover)}
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-100 truncate ">
                                        {musicPlayer.title}
                                    </p>
                                    <p class="text-sm text-gray-200 truncate">
                                        {musicPlayer.singer}
                                        
                                    </p>
                                </div>
                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {playing?(
                                        <span onClick={()=>{
                                            if(playing){
                                                setPlaying(false);
                                            }
                                        }} class="rounded-full cursor-pointer flex items-center justify-center w-7 h-7 bg-amber-500">
                                            <svg class="w-4 h-4 text-gray-800 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z" clip-rule="evenodd"/>
                                            </svg>

                                        </span>
                                    ):(
                                        <span onClick={()=>{
                                            
                                                setPlaying(true);
                                            
                                        }}  class="rounded-full cursor-pointer flex items-center justify-center w-7 h-7 bg-amber-500">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z" clip-rule="evenodd"/>
                                        </svg>


                                    </span>
                                    )}
                                    
                                </div>
                            </div>
                        
                        </li>
                    </ul>
                    <ReactPlayer
                        className='react-player'
                        url={musicPlayer.music_url}
                        width='100%'
                        height='100%'
                        playing={playing}
                    />
            
                </div>)
    }
    return null;
}
export default function Artist(){
    const [music, setMusic] = useState([]);
    const [details, setDetails] = useState([]);
    const [playlists, setPlaylists] = useState([]);
    const [musicId, setMusicId] = useState(null);
    const [showModal, setShowModal] = useState(false);     
    const [countPlaylists, setCountPlaylists] = useState(0);
    const [updatePhotoModal, setUpdatePhotoModal] = useState(false);  
    const [playing, setPlaying] = useState(false);  
    const [musicPlayer, setMusicPlayer] = useState(null);  
    const [photo, setPhoto] = useState(null); 
    const [userPosts, setUserPosts] = useState([]);
    const [showViewPost, setShowViewPost] = useState(false);
    const [postId, setPostId] = useState(null);
    //load posts 
    const [loadPosts, setLoadPosts] = useState(true);

    //Upload post files
    const [postFiles, setPostFiles] = useState([]);
    
    const [loading, setLoading] = useState(true);
    
    //Create post modal
    const [postModal, setPostModal] = useState(false);
    
    let { artist_id } = useParams();

    const fetchPosts = useCallback( async() => {
        //fetch UserProfile photos
        const {data:resp} = await izenuAPI.get("https://www.izenu.ao/api/distributor/artist/fetchPosts.php?user_id=" + artist_id );
          if (resp.response) {
            setUserPosts(resp.data);
          }
    
      },[artist_id]);

    //Fetcch artist data
    const fetchArtistDetails = useCallback(async ()=>{
        const distributor_id = localStorage.getItem('id');
        const END_POINT = `https://www.izenu.ao/api/distributor/artist/fetchArtistDetails.php?artist_id=${artist_id}&distributor_id=${distributor_id}`;
        const {data:r} = await izenuAPI.get(END_POINT);
        const {data:lists} = await izenuAPI.get(`https://www.izenu.ao/api/distributor/playlists/listByUserId.php?userId=${artist_id}`);
      
        setPlaylists(lists);
        
        if(r.response){
            setDetails(r.artist_details);
            setMusic(r.songs);
            setCountPlaylists(r.total_playlists);
        }
        
        setLoading(false);
    },[artist_id]);

    //delete music
    const deleteSong = async (id) =>{
        setLoading(true);
        try{
            const {data:r} = await izenuAPI.post("https://www.izenu.ao/api/distributor/artist/deleteMusic.php", {music_id:id});

            if(r.response){
                alert("Música apagada com sucesso!");
            }else{
                alert("Não foi possivel apagar música, tenta mais tarde");
                console.log(r);
            }
            setLoading(false);
        }catch(error){
            console.log(error);
            alert("Não foi possivel apagar música, tenta mais tarde");
            setLoading(false);
        }
       
    };   
    //Fetch all data
    useEffect(()=>{
        fetchPosts();
       fetchArtistDetails();
    }, [artist_id]);

    //handle posts views
    const handlePostsView = useCallback(()=>{
        if(userPosts.length > 0){
                return(
                    <div class="grid grid-cols-3 overflow-y-auto h-1/2">
                        <div className="aspect-square flex justify-center items-center bg-gray-100">
                            <button onClick={()=>{
                                setSelectedImages([]);
                                setPostModal(true)

                            }} className="border-2 text-amber-500 border-dark-500">
                                Adicionar publicação
                            </button>
                        </div>
                        {userPosts.map((e,i)=>{
                            
                            if(e.file_type == 'mp4'){
                                return (
                                    <div onClick={()=>{
                                        setPostId(e.post_id);
                                        setShowViewPost(true);
                                    }} className="aspect-square bg-gray-200 cursor-pointer active:opacity-50">
                                        
                                        <video className='w-full h-full object-cover'>
                                        <source src={e.video} />
                                        Your browser does not support the video tag.
                                        </video>
                                    </div>
                                )
                            }else{
                                return ( 
                                    <div  onClick={()=>{
                                        setPostId(e.post_id);
                                        setShowViewPost(true);
                                    }} className="aspect-square bg-gray-200 cursor-pointer active:opacity-50">
                                        <img src={e.photo} className='w-full h-full object-cover'/>
                                    </div>
                                )
                            }
                        })}
                        
                        
                    </div>    
                )
        }else{
            return (<div class="grid grid-cols-3 overflow-y-auto h-1/2">
                        <div className="aspect-square flex justify-center items-center bg-gray-100">
                            <button onClick={()=>setPostModal(true)} className="border-2 text-amber-500 border-amber-500">
                                Adicionar publicação
                            </button>
                        </div>
                        
                    </div>)
        }
    },[userPosts]);
    const handleMusicCover = useCallback((cover)=>{
        if(cover == null || cover == undefined){
            return (
                <div style={{width:45, backgroundColor:"#777", height:45, borderRadius:4}}>

                </div>);
        }else{
            return (
                <img class="w-8 h-8 border" src={cover.photo_low_quality_url}/>
          
            );
        }
    });

    const handleMusicState = useCallback((state)=>{
        if(state =="approved"){
            return (<span className='text-blue-500'>Aprovado</span>)
        }else if(state == "rejected"){
            return (<span className='text-rose-500'>Rejeitado</span>)
        }else{
            return (<span className='text-amber-500'>Pendente</span>)
        }
    });

    const uploadPhoto = useCallback(async ()=>{

        //Random string
        const str = Math.random().toString(36).slice(2,10);
        const photo_arr = photo.name.split('.');
        
        const d = new Date();
        const date = {day: d.getUTCDate(), year: d.getUTCFullYear(), month: d.getUTCMonth() + 1};
        

        const photo_name = `izenu_profile_${date.year}_${date.month}_${date.day}_${str}.${photo_arr[photo_arr.length - 1]}`;
        const photo_prefix = `users/${details.user_id}/profile_photos/`;
        const photo_data = new FormData();

        const key = photo_prefix + photo_name;
        photo_data.append(`files`, photo);
        photo_data.append(`key`, key);

        /*const form = new FormData();
          form.append('files', {name: photo_name, uri: photo.uri, type:'image/jpeg'});
          form.append('key', key);*/ 

        const {data:r} = await izenuAPI.post('https://server.izenu.net/profile/profile/upload-photo', photo_data, {
            headers: {
              'Content-Type': 'multipart/form-data',
        }});

        if(r.response){
            const {data:php} = await izenuAPI.post('https://www.izenu.ao/api/distributor/artist/updateProfilePhoto.php', {photo:photo_name, user_id: details.user_id});
            alert('Foto de perfil actualizada com sucesso.');
          }

    },[photo]);

    const handlePhotoChange =  useCallback((event)=>{
        const file = event.currentTarget.files[0];
   
        let arr =  file.name.split('.');
        let type = arr.pop();

        if(type != 'jpg' && type != 'jpeg' && type != 'png'){
            alert('Tipo de ficheiro invalido');
            setPhoto(null);
        }else{
            setPhoto(file);   
        }
    },[photo]);

    //Handle input files
    const handlePostfileInput = useCallback((file)=>{
       
       
        if(file){
            
            setPostFiles([...postFiles, file]);
        }
    },[postFiles]);

    const [selected, setSelected] = useState([]);

    const [selectedImages, setSelectedImages] = useState([]);
    const onChangeInput = useCallback((event)=>{
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
    
        const imagesArray = selectedFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });
    
        setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    
        // FOR BUG IN CHROME
        event.target.value = "";
    });
    //Show post files
    const handlePostFilesView = useCallback(()=>{

        if(selectedImages.length > 0){
           
            return (
                <div class="flex flex-1 flex-row border border-amber-500">
                    {selectedImages.map((image, index) => {
                        return (
                        
                            <img src={image} className='flex-1 aspect-square' alt="upload" />
                            
                        );
                    })}
                </div>
            )
        }else{
            return(<h1 className='text-gray-500'>As fotos e videos que selecionara apareceram aqui</h1>)
        }

    },[selectedImages]);

    //Loader
    if(loading){
        return (<div className="justify-center block items-center flex flex-col h-full flex-1 p-5 rounded-lg" style={{height:'100%'}}>
                    <div role="status">
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <h1 className="text-amber-500 font-light text-3xl">A carregar...</h1>
                    </div>
                </div>)
    }


    return(<> <div className="p-10">
            
            <Player musicPlayer={musicPlayer} playing ={playing} setPlaying={setPlaying}/>
            <div className="p-10 flex  flex-row space-x-4">
                <div className="relative border flex-1 py-5 rounded-lg">
                    <div class="flex flex-1 gap-10 mb-10">   
                        <div className='w-full flex flex-row flex-1'>
                            {details?.photo != "" || details?.photo != undefined?(
                                <div class="w-56 h-56 relative">
                                    <img  class="border w-56 h-56" src={`https://dpagz47n4g1lw.cloudfront.net/users/${details.user_id}/profile_photos/${details.photo}`} style={{borderRadius:'2%', objectFit:'cover'}}/>
                                    <div class="absolute bg-gray-950/200 left-0 top-0 w-full h-full flex justify-center border items-center">
                                        <button onClick={()=>setUpdatePhotoModal(true)} class="block text-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="button">
                                            Alterar foto
                                        </button>
                                    </div>
                                </div>
                            ):(
                                <div class="bg-gray-200 flex w-56 h-56 justify-center items-center">
                                    <svg class="w-full h-auto text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-width="2" d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                    </svg>
                                </div>
                            )}
                            <div class="flex-column flex-1 px-10 gap-10 text-left flex flex-col gap-5">
                                <h1 className='font-bold text-3xl'>{details.artist_name || details.name}</h1>
                                <h1>{music.length} Músicas •  {countPlaylists} Listas de reprodução</h1>
                                <p>{details.bio}</p>
                            </div>
                        </div>
                        
                    </div>
                                    
                </div>
                
            </div>
            <div className="flex flex-row space-x-4">
                <div className="relative flex-1 gap-2 border py-5 rounded-lg max-h-screen overflow-y-auto">
                    <ul class="w-full divide-y divide-gray-200 dark:divide-gray-700">
                        {music!= null?(
                                        <div>
                                            <li class="pb-3 sm:pb-4">
                                                                <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                                                    <div class="flex-shrink-0">
                                                                        Capa
                                                                    </div>
                                                                    <div class="flex-1 min-w-0">
                                                                        <p class="text-sm font-medium text-left text-gray-900 truncate dark:text-white">
                                                                            Detalhes
                                                                        </p>
                                                                    </div>
                                                                    <div class="flex min-w-0">
                                                                        
                                                                    </div>
                                                                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                        <span  class='text-sm text-gray-500 px-2'>
                                                                            Estado
                                                                        </span>
                                                                    </div>
                                                                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                        <span  class='text-sm text-gray-500 px-2'>
                                                                            Opção
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                            {music.map((e, i)=>{
                                                
                                                    return (<li class="pb-3 sm:pb-4 hover:bg-gray-200">
                                                                <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                                                    <div  onClick={()=>{
                                                                        setMusicPlayer(e);
                                                                        setPlaying(true);
                                                                        }} class="flex-shrink-0">
                                                                                        {handleMusicCover(e.cover)}
                                                                                    </div>
                                                                                    <div  onClick={()=>{
                                                                        setMusicPlayer(e);
                                                                        setPlaying(true);
                                                                        }}  class="flex-1 min-w-0 cursor-pointer">
                                                                        <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                                            {e.title}
                                                                        </p>
                                                                        <p class="text-sm text-gray-500 cursor-pointer truncate dark:text-gray-400">
                                                                            {e.singer || details.artist_name}
                                                                        </p>
                                                                    </div>
                                                                    <div class="flex min-w-0">
                                                                        <span onClick={()=>{
                                                                            setMusicId(e.music_id);
                                                                            setShowModal(true);
                                                                        }} class='text-sm text-gray-500 border py-1 cursor-pointer px-2 rounded-sm border-amber-500'>
                                                                            Adicionar a playlist
                                                                        </span>
                                                                    </div>
                                                                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                        {handleMusicState(e.music_state)}
                                                                    </div>
                                                                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                        <span onClick={()=>{
                                                                            deleteSong(e.music_id);
                                                                        }} className='text-rose-500 cursor-pointer'>Eliminar</span>
                                                                    </div>
                                                                </div>
                                                            </li>)
                                                })
                                            }
                                        </div> 
                            ):(
                                <div class="py-40">
                                    <h1 class="text-center">Ainda não carregou música</h1>
                                </div>
                            )
                            
                        }
                    </ul>
                </div>
            </div>
            </div>
            <PostViewModal show={showViewPost} setShow={setShowViewPost} postId={postId}/>
             {showModal?(
                <div className="modal w-full">
                    <div className="bg-gray-100 pb-5 px-5">
                        <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-between', padding:12, alignItems:'center'}}>
                            <h3>Salvar para</h3>
                            <button onClick={()=>setShowModal(false)}>
                                <AiOutlineClose size={30} color="#172b4d"/>
                            </button>
                        </div>
                        <div style={{flex:1, overflowY:'scroll', overflowX:'hidden'}}>
                            {playlists.length > 0?(
                                <>
                                    {playlists.map((e,i)=>{
                                        return (
                                            <PlaylistItem data={e} musicId={musicId} closeModal={setShowModal}/>
                                        )
                                    })}
                                </>
                            ):(
                                <div className="">
                                        <h1 class="text-gray-500 font-sm">Ainda não criou playlist para este utilizador</h1>
                                </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            ):(<>
            </>)}

            {postModal?(
                <div className="modal w-full">
                    <div className="flex flex-col bg-gray-100 h-4/6 w-1/4 pt-10 overflow-hidden">
                        <div className='flex justify-between px-5'>
                            <div className='flex flex-1'>
                                <h1>Adicionar publicação</h1>
                            </div>
                            
                            <button onClick={()=>setPostModal(false)}>
                                <AiOutlineClose size={30} color="#172b4d"/>
                            </button>
                        </div>
                        <div  className='flex flex-1 flex-col'>
                            <div className='flex flex-1 w-full'>
                                <Carousel>
                                    {selectedImages.map((e,i)=>{
                                        return ( <img src={e} className='flex-1 aspect-square' alt="upload" />)
                                    })}
                                </Carousel>  
                            </div>
                            <div className='py-5'>
                                {selectedImages.length == 0?(
                                    <>
                                        <label for="files" className='bg-amber-500 px-5 py-2 cursor-pointer active:bg-amber-400'>
                                            Selecionar do computador
                                        </label>
                                        <input id="files" onChange={onChangeInput}  multiple class="hidden" accept="image/gif, image/jpeg, image/jpg, video/mp4" type='file'/>
                                    </>
                                ):(
                                    <button className='bg-amber-500 px-5 py-2 cursor-pointer active:bg-amber-400'>
                                        Proximo
                                    </button>
                                )}
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            ):(<></>)}

          
             {/**UPDATE FOTO MODAL */}
             {updatePhotoModal?(
                <div  style={{backgroundColor:"#0005", height:'100%'}} class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex">
                    <div class="relative p-4 w-full max-w-md max-h-full">
                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        
                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                    Actualizar foto de perfil
                                </h3>
                                <button type="button" onClick={()=>setUpdatePhotoModal(false)} class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                    </svg>
                                </button>
                            </div>
                        
                            <div class="p-4 md:p-5">
                                <form onSubmit={(e)=>{
                                    e.preventDefault();
                                    uploadPhoto();
                                }} class="space-y-4" action="#">
                                    <div>
                                        <input onChange={handlePhotoChange} accept="image/png, image/jpeg, image/jpg" id="photo" name="photo" type="file" placeholder='Musica' class="block w-full text-sm focus:border-amber-500 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help"/>
                                    </div>              
                                
                                    <button type="submit" class="w-full text-white bg-amber-500 hover:bg-amber-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Actualizar foto</button>
                                
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
            ):(
                <></>
            )}
            </>
            ); 
}